import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { BlogTeaserSection } from '../components/BlogTeaser';
import { ButtonRecipe } from '../components/Button.css';
import ContentSection from '../components/ContentSection';
import FaqSection from '../components/FaqSection';
import Hero from '../components/Hero';
import Layout, { Section } from '../components/Layout';
import Link from '../components/Link';
import QuickInsights from '../components/QuickInsights';
import { Seo } from '../components/Seo';
import TestimonialSection from '../components/Testimonial';
import { Text, Title } from '../components/Text';
import ThoughtBubblesPerson from '../components/ThoughtBubblesSection';
import ContactUsSvg from '../images/contact-us-amico.svg';
import BusinessmanSvg from '../images/software-consult/businessman-amico-1.svg';
import EfficiencySvg from '../images/software-consult/efficiency-amico-1.svg';
import HeroImage from '../images/software-consult/hero.svg';
import LawFirmSvg from '../images/software-consult/how-it-works/law-firm-amico-1.svg';
import LawyerSvg from '../images/software-consult/how-it-works/lawyer-amico-1.svg';
import SetupWizardSvg from '../images/software-consult/how-it-works/setup-wizard-amico-1.svg';
import SigningAContractSvg from '../images/software-consult/how-it-works/signing-a-contract-amico-1.svg';
import WorkTimeSvg from '../images/software-consult/how-it-works/work-time-amico-1.svg';
import InternshipSvg from '../images/software-consult/internship-amico-1.svg';
import NewEmployeeSvg from '../images/software-consult/new-employee-amico-1.svg';
import { sprinkles } from '../sprinkles.css';

const SoftwareConsultPage = ({
	data: { testimonialsData, faqData, blogs },
}: {
	data: {
		blogs: { edges: Queries.MdxEdge[] };
		locales: { edges: Queries.LocaleEdge[] };
		testimonialsData: { nodes: Queries.TestimonialsJson[] };
		faqData: { nodes: Queries.FaqJson[] };
	};
}) => {
	const {
		t,
		i18n: { language },
	} = useTranslation('SoftwareConsultPage');

	if (language === 'en') {
		return (
			<Layout padding={false}>
				<Hero
					width="wide"
					title="Your whistleblowing success partner"
					text={
						<Text>
							<strong>
								Are you a lawyer, external data protection officer or consultant
							</strong>{' '}
							and do you want to offer your clients the best possible service when it
							comes to assuming the obligations under the EU Whistleblower Directive?
						</Text>
					}
					actions={[
						<Link
							key={0}
							to="https://my.meetergo.com/martinmeng/softwareconsult"
							className={ButtonRecipe({ variant: 'primary' })}
						>
							Book a demo
						</Link>,
					]}
					image={
						<img
							src={HeroImage}
							alt="konfidal slogan 'fast simple compliant' surrounding a depictions of an ongoing meeting with one person talking about konfidal."
							width="100%"
							height="auto"
							className={sprinkles({
								width: 'fill',
								minWidth: {
									mobile: 'three',
									tablet: 'fourhalf',
								},
							})}
						/>
					}
				/>
				<ThoughtBubblesPerson
					bubbleLeftText={
						<Text variant="secondary" align="center">
							You bring the legal expertise to your clients and they are happy to hand
							over the operation of the reporting channel and the whistleblower reporting
							centre to you?
						</Text>
					}
					bubbleRightText={
						<strong>But you still lack the right software solution?</strong>
					}
				/>
				<ContentSection
					titleLevel="paragraph"
					side="left"
					imageSrc={EfficiencySvg}
					imageAlt="A woman rolls a blue ball quickly forward while saying 'konfidal' next to two other people that push grey blocks slowly."
					title={{
						__html:
							'For this purpose, <strong>konfidal</strong> has developed a&nbsp;highly specialised whistleblower software solution for you',
					}}
					text={{
						__html:
							'With it providers can potentially operate an unlimited number of whistleblower channels and maintain the whistleblower operation for an unlimited number of clients. Become the ombudsman centre for all your clients with Konfidal.',
					}}
				/>
				<ContentSection
					titleLevel="paragraph"
					side="right"
					imageSrc={InternshipSvg}
					imageAlt="A woman sits in front of a desktop with questionmarks floating over her head while a colleage next to her gives advice."
					title={{
						__html:
							'By taking over the operation of the whistleblower office for your clients, you move closer to your clients in terms of content and can provide support at an early stage when it comes to taking on further mandates resulting from whistleblower cases.',
					}}
					text={{
						__html:
							'Your client has the good feeling that he receives advice and whistleblower services from a single source and no longer has to worry about his legal compliance.',
					}}
				/>
				<ContentSection
					titleLevel="paragraph"
					side="left"
					imageSrc={BusinessmanSvg}
					imageAlt="A has a phone call standing next to a char and a workdesk with a monitor behind his back."
					title={{
						__html:
							'The <strong>konfidal Whistleblower Software Partner Package</strong> also includes that you as&nbsp;a&nbsp;service provider only pay when you have won clients for your full service',
					}}
					text={{
						__html:
							'So you have <b>no risk of upfront investments</b> that may not pay off at all. With konfidal you are profitable from day one!',
					}}
				/>
				<ContentSection
					background="blue"
					side="right"
					imageSrc={ContactUsSvg}
					imageAlt="A customer support employee inside a smartphone"
					title={{
						__html: 'Talk to sales now and supply your customers immediately!',
					}}
					cta1Text="Book a demo"
					cta1Link="https://my.meetergo.com/martinmeng/softwareconsult"
				/>
				<Section width="wide" gap="largerr">
					<Title level="section" align="center">
						How it works
					</Title>
					<QuickInsights
						insights={[
							{
								title: '1. Offer',
								text: (
									<Text>
										Offer your clients the full service of the Ombudsman to save them time
										and money.
									</Text>
								),
								icon: (
									<img
										style={{ maxHeight: '100%' }}
										width="100%"
										src={LawyerSvg}
										alt="Lawyer"
									/>
								),
							},
							{
								title: '2. Contract',
								text: (
									<Text>
										Offer your clients the full service of the Ombudsman to save them time
										and money.
									</Text>
								),
								icon: (
									<img
										style={{ maxHeight: '100%' }}
										width="100%"
										src={SigningAContractSvg}
										alt="Lawyer"
									/>
								),
							},
							{
								title: '3. Setup',
								text: (
									<Text>
										Set up the client in the konfidal software in minutes and share with
										them the link to their company-specific whistleblower reporting portal
										so that the client&apos;s employees and stakeholders can report hints
										at any time.
									</Text>
								),
								icon: (
									<img
										style={{ maxHeight: '100%' }}
										width="100%"
										src={SetupWizardSvg}
										alt="Lawyer"
									/>
								),
							},
							{
								title: '4. Operation',
								text: (
									<Text>
										Work on whistleblower cases for your clients and bring them to a
										successful conclusion. If necessary, initiate internal investigations
										in consultation with your client or conduct litigation cases for your
										client.
									</Text>
								),
								icon: (
									<img
										style={{ maxHeight: '100%' }}
										width="100%"
										src={LawFirmSvg}
										alt="Lawyer"
									/>
								),
							},
							{
								title: '5. All-in-one Solution',
								text: (
									<Text>
										To do this, work directly with your client in the konfidal software.
										This means that the highly sensitive data never has to leave the
										closed system.Offer your clients the full service of the Ombudsman to
										save them time and money.
									</Text>
								),
								icon: (
									<img
										style={{ maxHeight: '100%' }}
										width="100%"
										src={WorkTimeSvg}
										alt="Lawyer"
									/>
								),
							},
							{
								icon: (
									<img
										style={{ maxHeight: '100%' }}
										width="100%"
										src={NewEmployeeSvg}
										alt="Closing business with konfidal"
									/>
								),
								title: <strong>Result</strong>,
								text: 'Relieved clients and a prosperous additional business model for your law firm.',
								ctaTitle: 'Want to learn more?',
								ctaText: 'Contact Sales',
								ctaLink: 'https://my.meetergo.com/martinmeng/softwareconsult',
							},
						]}
					/>
				</Section>
				<TestimonialSection testimonials={testimonialsData.nodes} />
				<FaqSection
					content={faqData.nodes[0].entries.filter((n) =>
						n.pages.includes('/software-consult'),
					)}
					width="wide"
				/>
				<BlogTeaserSection
					posts={blogs.edges
						.map(({ node }) => node)
						.sort(
							(a, b) =>
								(b.frontmatter?.lastmod &&
									a.frontmatter?.lastmod?.localeCompare(b.frontmatter?.lastmod)) ||
								0,
						)
						.slice(0, 2)}
				/>
			</Layout>
		);
	} else if (language === 'de') {
		return (
			<Layout padding={false}>
				<Hero
					width="wide"
					title="Ihr Whistleblower Software-Partner für Ihre Kunden"
					text={
						<Text>
							<strong>
								Sie sind Anwalt, externer Datenschutzbeauftragter (DSB) oder
								IT-Security-Berater?
							</strong>{' '}
							Sie wollen Ihren Kunden den bestmöglichen Service bieten, wenn es um die
							Übernahme der Verpflichtungen aus dem Hinweisgeberschutzgesetz geht?
						</Text>
					}
					actions={[
						<Link
							key={0}
							to="https://my.meetergo.com/martinmeng/softwareconsult"
							className={ButtonRecipe({ variant: 'primary' })}
						>
							Jetzt klären wie
						</Link>,
					]}
					image={
						<img
							src={HeroImage}
							alt="Der konfidal-Slogan 'einfach, schnell und konform' umgibt die Darstellung eines laufenden Gesprächs mit einer Person, die über konfidal spricht."
							width="100%"
							height="auto"
							className={sprinkles({
								width: 'fill',
								minWidth: {
									mobile: 'three',
									tablet: 'fourhalf',
								},
							})}
						/>
					}
				/>
				<ThoughtBubblesPerson
					bubbleLeftText={
						<Text variant="secondary" align="center">
							Ihre Kunden wären froh, wenn Sie für sie den Meldestellenbetrieb nach
							HinSchG übernehmen würden?
						</Text>
					}
					bubbleRightText={<strong>Aber Ihnen fehlt die passende Softwarelösung?</strong>}
				/>
				<ContentSection
					titleLevel="subsection"
					side="left"
					imageSrc={EfficiencySvg}
					imageAlt="Eine Frau rollt eine blaue Kugel schnell vorwärts, während sie 'konfidal' sagt. Daneben stehen zwei andere Personen, die langsam graue Blöcke schieben."
					title={{
						__html:
							'Deshalb hat <strong>konfidal</strong> eine hochspezialisierte Whistleblower-Software für Dienstleister entwickelt',
					}}
					text={{
						__html:
							'Damit können Sie eine unbegrenzte Anzahl von Hinweisgeberkanälen betreiben und den Meldestellenbetrieb für Ihre Unternehmenskunden aufrechterhalten. Werden Sie mit konfidal die Ombudsstelle für alle Ihre Kunden!',
					}}
				/>
				<ContentSection
					titleLevel="paragraph"
					side="right"
					imageSrc={InternshipSvg}
					imageAlt="Eine Frau sitzt vor einem Schreibtisch mit Fragezeichen, die über ihrem Kopf schweben, während ein Kollege neben ihr Ratschläge gibt."
					title={{
						__html:
							'Die Übernahme der Whistleblower-Meldestelle für Ihre Mandanten stärkt Ihre Beziehungen und ermöglicht eine frühzeitige Unterstützung bei weiteren aus Whistleblower-Fällen resultierenden Mandaten.',
					}}
					text={{
						__html:
							'Ihr Kunde hat das gute Gefühl, dass er Beratung und Whistleblower-Dienste aus einer Hand erhält und sich nicht mehr um die Einhaltung von Rechtsvorschriften kümmern muss. So lagert man compliance-relvante Geschäftsprozesse sinnhaft aus.',
					}}
				/>
				<ContentSection
					titleLevel="paragraph"
					side="left"
					imageSrc={BusinessmanSvg}
					imageAlt="Ein Mensch hat ein Telefonat neben einem Stuhl und einem Arbeitstisch mit einem Monitor im Rücken."
					title={{
						__html:
							'Das <strong>konfidal Whistleblower Software Partner Paket</strong> beinhaltet auch, dass Sie als Dienstleister nur dann zahlen, wenn Sie Kunden für Ihren Full Service als Ombudsmann gewonnen haben',
					}}
					text={{
						__html:
							'Sie haben also <b>kein Risiko von Vorabinvestitionen</b>, die sich vielleicht gar nicht auszahlen. Mit konfidal sind Sie vom ersten Tag an profitabel!',
					}}
				/>
				<ContentSection
					background="blue"
					side="left"
					imageSrc={ContactUsSvg}
					imageAlt="Ein Mitarbeiter des Kundensupports im Inneren eines Smartphones"
					title={{
						__html:
							'Lassen Sie uns besprechen, wie Sie Ihre Kunden und Mandanten schnell mit Ihrem Service entlasten können!',
					}}
					cta1Text="Jetzt Termin buchen"
					cta1Link="https://my.meetergo.com/martinmeng/softwareconsult"
				/>
				<Section width="wide" gap="largerr">
					<Title level="section" align="center">
						Wie es funktioniert
					</Title>
					<QuickInsights
						insights={[
							{
								title: '1. Angebot',
								text: (
									<Text>
										Bieten Sie Ihren Kunden den vollen Service des Meldestellenbetriebs
										an, um ihnen Zeit und Geld zu sparen.
									</Text>
								),
								icon: (
									<img
										style={{ maxHeight: '100%' }}
										width="100%"
										src={LawyerSvg}
										alt="Rechtsanwalt"
									/>
								),
							},
							{
								title: '2. Vertragsschluss',
								text: (
									<Text>
										Nachdem Sie mit Ihrem Kunden bzw. Mandaten über grundlegene Kosten und
										aufwandsbezogene Stundensätze handelseinig geworden sind, schließen
										Sie den Vertrag für die Übernahme des Meldestellenbetriebs nach
										HinSchG
									</Text>
								),
								icon: (
									<img
										style={{ maxHeight: '100%' }}
										width="100%"
										src={SigningAContractSvg}
										alt="Vertragsunterzeichnung"
									/>
								),
							},
							{
								title: '3. Setup',
								text: (
									<Text>
										Richten Sie den Kunden in wenigen Minuten in der konfidal Software ein
										und teilen Sie ihm den Link zu seinem unternehmens&shy;spezifischen
										Whistleblower-Meldeportal, so dass alle Beschäftigte und weitere
										Anspruchs&shy;berechtigte des Kunden jederzeit Hinweise abgeben
										können.
									</Text>
								),
								icon: (
									<img
										style={{ maxHeight: '100%' }}
										width="100%"
										src={SetupWizardSvg}
										alt="Einrichtungsprozess"
									/>
								),
							},
							{
								title: '4. Meldestellenbetrieb',
								text: (
									<Text>
										Bearbeiten Sie Whistleblower-Fälle für Ihre Mandanten und bringen Sie
										sie zu einem erfolgreichen Abschluss. Falls erforderlich, leiten Sie
										interne Untersuchungen in Absprache mit Ihrem Mandanten ein, geben den
										Hinweisgebenden die gesetzlich vorgeschriebenen Rückmeldungen oder
										führen für Ihren Kunden fallbezogene Gerichtsverfahren.
									</Text>
								),
								icon: (
									<img
										style={{ maxHeight: '100%' }}
										width="100%"
										src={LawFirmSvg}
										alt="Kanzlei"
									/>
								),
							},
							{
								title: '5. Alle Prozessbeteiligten dabei',
								text: (
									<Text>
										Dazu arbeiten Sie direkt mit Ihrem Kunden in der konfidal Software.
										Das bedeutet, dass die hochsensiblen Daten nie das geschlossene System
										verlassen müssen. Bieten Sie Ihren Kunden den vollen Service als
										Ombudsperson an, um sie zu entlasten.
									</Text>
								),
								icon: (
									<img
										style={{ maxHeight: '100%' }}
										width="100%"
										src={WorkTimeSvg}
										alt="Zeitersparnis"
									/>
								),
							},
							{
								icon: (
									<img
										style={{ maxHeight: '100%' }}
										width="100%"
										src={NewEmployeeSvg}
										alt="Geschäftsabschluss mit der Firma konfidal"
									/>
								),
								title: <strong>Im Ergbnis</strong>,
								text: 'Erleichterte Kunden und ein profitables zusätzliches Geschäftsmodell für Ihre Kanzlei.',
								ctaTitle: 'Sie möchten eine Demo oder mehr wissen?',
								ctaText: 'Vertrieb kontaktieren',
								ctaLink: 'https://my.meetergo.com/martinmeng/softwareconsult',
							},
						]}
					/>
				</Section>
				<TestimonialSection testimonials={testimonialsData.nodes} />
				<FaqSection
					content={faqData.nodes[0].entries.filter((n) =>
						n.pages.includes('/software-consult'),
					)}
					width="wide"
				/>
				<BlogTeaserSection
					posts={blogs.edges
						.map(({ node }) => node)
						.sort(
							(a, b) =>
								(b.frontmatter?.lastmod &&
									a.frontmatter?.lastmod?.localeCompare(b.frontmatter?.lastmod)) ||
								0,
						)
						.slice(0, 2)}
				/>
			</Layout>
		);
	}
};

export const Head = () => {
	return <Seo />;
};

export default SoftwareConsultPage;
export const query = graphql`
	query SoftwareConsultPageQuery($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
		testimonialsData: allTestimonialsJson(
			filter: { language: { eq: $language }, slug: { in: ["ferox-legal"] } }
		) {
			nodes {
				name
				company
				text
				companyLogo {
					childImageSharp {
						gatsbyImageData(height: 50)
					}
				}
				companyLogoAlt
				personImage {
					childImageSharp {
						gatsbyImageData(layout: FULL_WIDTH)
					}
				}
				personImageAlt
				link
			}
		}
		faqData: allFaqJson(filter: { language: { eq: $language } }) {
			nodes {
				entries {
					title
					content
					pages
				}
			}
		}
		blogs: allMdx(
			filter: {
				internal: { contentFilePath: { glob: "**/blog/**" } }
				frontmatter: { language: { eq: $language }, path: { ne: "how-to-implement" } }
			}
		) {
			edges {
				node {
					id
					frontmatter {
						title
						published_at
						path
						author
						featured_image {
							src {
								childImageSharp {
									gatsbyImageData(layout: FULL_WIDTH)
								}
							}
							alt
						}
					}
					excerpt(pruneLength: 250)
					internal {
						contentFilePath
					}
				}
			}
		}
	}
`;
